import "./index.scss";

/**
 * Import modules
 */
import { Fancybox } from "@fancyapps/ui";
import Inputmask from "inputmask/dist/inputmask.min.js";
import lozad from "lozad";
import Toastify from "toastify-js";
// import { transition } from "../../../../../wp-includes/js/codemirror/csslint";

/**
 * Helpers
 */
const accordion = (trigger) => {
  let accordion = trigger.parentNode,
    content = accordion.querySelector(".accordion-content");

  accordion.classList.toggle("active");

  if (accordion.classList.contains("active")) {
    let transition = ".3s";
    if (content.scrollHeight > 300) {
      transition = content.scrollHeight / 1000 + "s";
    }
    content.style.maxHeight = content.scrollHeight + "px";
    content.style.transition = "max-height " + transition;
  } else {
    content.style.maxHeight = null;
    content.style.transition = null;
  }
};
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show(
    [
      {
        src: src,
        type: type,
      },
    ],
    {
      autoFocus: false,
    }
  );
};
const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => { },
  }).showToast();
};
const scrollTo = (target) => {
  document
    .querySelector(target)
    .scrollIntoView({ block: "start", behavior: "smooth" });
};
const addModalEvent = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("modal-rendered") == false) {
      el.classList.add("modal-rendered");

      el.addEventListener("click", (evt) => {
        evt.preventDefault();

        const button = evt.currentTarget,
          target = button.hash || button.dataset.href,
          popup = document.querySelector(target),
          title = button.dataset.modalTitle,
          content = button.dataset.modalContent,
          footer = button.dataset.modalFooter,
          submit = button.dataset.modalSubmit,
          yaGoal = button.dataset.modalYagoal,
          email = button.dataset.modalEmail;

        if (title) popup.querySelector(".modal__title span").innerHTML = title;
        if (submit) popup.querySelector("input[type='submit']").value = submit;
        if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
        if (email)
          popup.querySelector("input[name='email_title']").value = email;
        if (target) fancyboxShow(target, "inline");
      });
    }
  });
};
const inputValidate = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("validate-rendered") == false) {
      el.classList.add("validate-rendered");

      if (selector === "input[name='agreement']") {
        el.addEventListener("change", () => {
          const status = el.checked;

          if (status) {
            el.closest("form")
              .querySelector("input[type='submit']")
              .removeAttribute("disabled");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.remove("disabled");
          } else {
            el.closest("form")
              .querySelector("input[type='submit']")
              .setAttribute("disabled", "");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.add("disabled");
          }
        });
      }
      if (selector === "input[name='name']") {
        let counter = 0;

        el.addEventListener("keyup", () => {
          if (cyrValue(el.value)) {
            counter++;

            if (counter > 5) {
              toast("Внимание!", "Имя должно быть на русском языке", "warning");
              counter = 0;
            }
          }

          el.value = el.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
        });
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[name='phone']") {
        let im = new Inputmask({
          mask: "+7 (e99) 999-99-99",
          definitions: {
            e: {
              validator: "[0-7,9]",
            },
          },
        });
        im.mask(el);

        el.addEventListener("blur", () => {
          if (el.value[17] == "_" || el.value[17] == undefined) {
            toast("Внимание!", "Некорректный номер", "warning");
            el.parentNode.classList.add("error");
            el.parentNode.classList.remove("valid");
          } else {
            el.parentNode.classList.add("valid");
            el.parentNode.classList.remove("error");
          }
        });
      }
      if (selector === "input[type='email']") {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        el.addEventListener("blur", () => {
          el.parentNode.classList.remove("error");

          if (reg.test(el.value)) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");

            if (el.required) {
              el.parentNode.classList.add("error");

              if (el.value.length > 0) {
                toast("Внимание!", "Укажите корректный E-mail", "warning");
              } else {
                toast("Внимание!", "E-mail обязателен", "warning");
              }
            }
          }
        });
      }
      if (selector === "input[name='file_attach[]']") {
        el.addEventListener("change", () => {
          let totalSize = 0;
          Object.keys(e.currentTarget.files).map((objectKey, index) => {
            totalSize = totalSize + el.files[index].size;
          });
          if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальный объём вложений - 10 мб.",
              "warning"
            );
          } else if (el.files.length > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальное количество вложений - 10 шт.",
              "warning"
            );
          } else {
            el.closest("form").querySelector("#file-name").innerText =
              el.files.length +
              " шт. (" +
              Math.round((totalSize / 1048576) * 100) / 100 +
              " мб.)";
            el.closest("form")
              .querySelector("p.filename")
              .classList.add("is-loaded");
          }
        });
        el.closest("form")
          .querySelector("#file-clear")
          .addEventListener("click", (evt) => {
            el.value = "";
            evt.currentTarget
              .closest("form")
              .querySelector("p.filename")
              .classList.remove("is-loaded");
            document.querySelector("#file-name").innerText = "";
          });
      }
      if (selector === "input[type='text']" || selector === "textarea") {
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[required]") {
        el.addEventListener("blur", () => {
          if (el.value.length == 0) {
            toast("Внимание!", "Поле обязательно для заполнения", "warning");
          }
        });
      }
    }
  });
};

/**
 * Accordions init
 */
if (document.querySelector("button.accordion-trigger")) {
  document.querySelectorAll("button.accordion-trigger").forEach((btn) => {
    btn.addEventListener("click", () => accordion(btn));
  });
}

/**
 * Animated elements
 */
const animations_observer = lozad(".lazy-animation", {
  loaded: (el) => {
    let clientWidth = document.body.clientWidth,
      delay = (Number(el.dataset.delay) - 1) / 2;

    el.classList.add("animate__animated", "animate__fadeInUp");

    if (clientWidth > 415) {
      el.style.setProperty("animation-delay", delay + "s");
    }
  },
});
animations_observer.observe();

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']"))
  inputValidate("input[name='agreement']");
if (document.querySelector("input[name='name']"))
  inputValidate("input[name='name']");
if (document.querySelector("input[name='phone']"))
  inputValidate("input[name='phone']");
if (document.querySelector("input[required]")) inputValidate("input[required]");
if (document.querySelector("input[type='text']"))
  inputValidate("input[type='text']");
if (document.querySelector("input[type='email']"))
  inputValidate("input[type='email']");
if (document.querySelector("textarea")) inputValidate("textarea");
if (document.querySelector("input[name='file_attach[]']"))
  inputValidate("input[name='file_attach[]']");

/**
 * Form
 */
// if (document.querySelector("form.fetch")) {
//   document.querySelectorAll("form.fetch").forEach((form) => {
//     form.addEventListener("submit", (evt) => {
//       evt.preventDefault();
//       let yaGoal = form.querySelector("input[name='ya_goal']").value,
//         phone = form.querySelector("input[name='phone']").value,
//         yaMetrikaId = wp_data.ya_metrika_id,
//         yaMetrikaGoal = wp_data.ya_metrika_goal_id;

//       console.log(2);

//       if (phone.length[17] === "_" || phone.length === 0) {
//         document.querySelector("input[name='phone']").classList.add("is-error");
//       } else {
//         fancyboxShow("#loading", "inline");
//         if (form.querySelector("input[type='submit']"))
//           form
//             .querySelector("input[type='submit']")
//             .setAttribute("disabled", "");
//         if (form.querySelector("button[type='submit']"))
//           form
//             .querySelector("button[type='submit']")
//             .setAttribute("disabled", "");

//         let data = new FormData(form);
//         mna100("email", data, (r) => {
//           if (r.status === 1) {
//             Fancybox.close();

//             if (form.querySelector("input[type='submit']"))
//               form
//                 .querySelector("input[type='submit']")
//                 .removeAttribute("disabled", "");
//             if (form.querySelector("button[type='submit']"))
//               form
//                 .querySelector("button[type='submit']")
//                 .removeAttribute("disabled", "");

//             form.querySelectorAll("input").forEach((inp) => {
//               if (
//                 inp.type !== "submit" &&
//                 inp.type !== "checkbox" &&
//                 inp.type !== "radio" &&
//                 inp.type !== "hidden"
//               ) {
//                 inp.value = "";
//                 inp.parentNode.classList.remove("valid");
//               }
//             });
//             if (form.querySelector("textarea")) {
//               form.querySelector("textarea").value = "";
//               form
//                 .querySelector("textarea")
//                 .parentNode.classList.remove("valid");
//             }
//             if (form.querySelector("input[type='checkbox']")) {
//               form.querySelectorAll("input[type='checkbox']").forEach((el) => {
//                 if (el.name != "agreement") el.checked = false;
//               });
//             }

//             setTimeout(() => {
//               fancyboxShow("#thanks", "inline");
//             }, 150);
//             setTimeout(() => {
//               Fancybox.close();
//             }, 5000);

//             if (typeof ym === "function" && yaMetrikaId) {
//               let goal = yaMetrikaGoal ? yaMetrikaGoal : yaGoal;
//               ym(yaMetrikaId, "reachGoal", goal);
//               console.log("Цель достигнута: " + goal);
//             }
//             if (typeof gtag === "function") {
//               gtag("event", "form_lead", {
//                 event_category: "lead",
//                 event_action: "zayavka",
//               });
//             }
//           } else {
//             toast("Внимание!", "Ошибка! " + r.message, "error");

//             Fancybox.close();
//             form
//               .querySelector("input[type='submit']")
//               .removeAttribute("disabled", "");
//           }
//         });
//       }
//     });
//   });
// }


if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach(form => {
    form.addEventListener("submit", evt => {
      evt.preventDefault();
      let yaGoal = form.querySelector("input[name='ya_goal']").value,
        phone = form.querySelector("input[name='phone']").value,
        yaMetrikaId = wp_data.ya_metrika_id,
        yaMetrikaGoal = wp_data.ya_metrika_goal_id;

      if ((phone.length[17] === "_") || (phone.length === 0)) {
        document.querySelector("input[name='phone']").classList.add("is-error");
      } else {
        fancyboxShow("#loading", "inline");
        if (form.querySelector("input[type='submit']")) form.querySelector("input[type='submit']").setAttribute("disabled", "");
        if (form.querySelector("button[type='submit']")) form.querySelector("button[type='submit']").setAttribute("disabled", "");

        let data = new FormData(form);
        mna100("email", data, r => {
          if (r.status === 1) {
            Fancybox.close();

            if (form.querySelector("input[type='submit']")) form.querySelector("input[type='submit']").removeAttribute("disabled", "");
            if (form.querySelector("button[type='submit']")) form.querySelector("button[type='submit']").removeAttribute("disabled", "");

            form.querySelectorAll("input").forEach(inp => {
              if ((inp.type !== "submit") && (inp.type !== "checkbox") && (inp.type !== "radio") && (inp.type !== "hidden")) {
                inp.value = "";
                inp.parentNode.classList.remove("valid");
              }
            })
            if (form.querySelector("textarea")) {
              form.querySelector("textarea").value = "";
              form.querySelector("textarea").parentNode.classList.remove("valid");
            }
            if (form.querySelector("input[type='checkbox']")) {
              form.querySelectorAll("input[type='checkbox']").forEach(el => {
                if (el.name != "agreement") el.checked = false;
              })
            }

            setTimeout(() => {
              fancyboxShow("#thanks", "inline");
            }, 150);
            setTimeout(() => {
              Fancybox.close();
            }, 5000);

            if (typeof (ym) === 'function' && yaMetrikaId) {
              let goal = (yaMetrikaGoal) ? yaMetrikaGoal : yaGoal;
              ym(yaMetrikaId, 'reachGoal', goal);
              console.log('Цель достигнута: ' + goal);
            }
            if (typeof (gtag) === 'function') {
              gtag('event', 'form_lead', { 'event_category': 'lead', 'event_action': 'zayavka' });
            }
          } else {
            toast("Внимание!", "Ошибка! " + r.message, "error");

            Fancybox.close();
            form.querySelector("input[type='submit']").removeAttribute("disabled", "");
          }
        })
      }
    })
  })
}

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**
 * Modal
 */
if (document.querySelector(".modal-link")) addModalEvent(".modal-link");

/**
 * Scroll to elements init
 */
if (document.querySelector(".scroll-to")) {
  document.querySelectorAll(".scroll-to").forEach((btn) => {
    btn.addEventListener("click", (evt) => {
      evt.preventDefault();
      scrollTo(btn.hash);
    });
  });
}

/**
 * Swiper channel prices
 */
if (document.querySelector(".swiper.channel-prices-desktop")) {
  new Swiper(".swiper.channel-prices-desktop", {
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.channel-prices-desktop",
      hide: true,
    },
    slidesPerView: "auto",
  });
}
